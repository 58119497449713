$border_color:#E1E1E1;
$white: #fff;
$black: #000;
$font_color:#000;

.pioromycalendar2_ajax_box_pagination {

    .paggination_box {
        width: 100%;
        display: table;
        height: 68px;
        border: 1px solid $border_color;
        background: $white;
        margin-bottom: 60px;
        @media only screen and (max-width: 991px) {
            display: block;
            height: auto;
            padding: 15px 0;
            margin-bottom: 30px;
        }

        > div {
            display: table-cell;
            vertical-align: middle;
            height: 100%;
            @media only screen and (max-width: 991px) {
                display: block;
            }

            &.numbers {
                text-align: center;
                @media only screen and (max-width: 991px) {
                    padding: 15px 0;
                }

                ul {
                    li {

                        &.active, &:hover {
                            a {
                                cursor: pointer;

                                color: $black;
                                font-size: 25px;
                                text-decoration: none;
                            }
                        }

                        margin-right: 20px;
                        display: inline-block;
                        vertical-align: top;

                        a {
                            font-size: 16px;
                            line-height: 16px;
                            font-weight: 600;
                            color: #797979;

                        }

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }

            &.btn_sites {
                width: 235px;
                text-align: center;
                @media only screen and (max-width: 991px) {
                    width: auto;
                }

                a {
                    text-decoration: none;
                }

                &.prev {
                    border-right: 1px solid $border_color;
                    @media only screen and (max-width: 991px) {
                        border-right: none;
                    }

                    a {
                        cursor: pointer;

                        padding-left: 30px;
                        background-repeat: no-repeat;
                        background-position: left center;
                        background-image: url('/img/carousel_arr_left.png');
                        background-size: 12px;
                        color: $font_color;

                    }
                }

                &.next {
                    border-left: 1px solid $border_color;
                    @media only screen and (max-width: 991px) {
                        border-left: none;
                    }

                    a {
                        cursor: pointer;

                        color: $font_color;
                        padding-right: 30px;
                        background-repeat: no-repeat;
                        background-position: right center;
                        background-image: url('/img/carousel_arr_right.png');
                        background-size: 12px;
                    }
                }
            }
        }
    }
}
